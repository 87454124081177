import React, { useRef, useState } from "react";
import { graphql, Link } from "gatsby";
import emailjs from "@emailjs/browser";

import Layout from "../components/Layout/Layout";
import PageDetails from "../components/PageDetails/PageDetails";

import { contactForm, submitMessage } from "./contact.module.scss";

const Contact = ({ data }) => {
  const { contentfulContactPage } = data;
  const { pageTitle, pageDescription } = contentfulContactPage;

  const [formSubmitStatus, setFormSubmitStatus] = useState("pending");

  const formRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.GATSBY_EMAILJS_SERVICE_ID,
        process.env.GATSBY_EMAILJS_TEMPLATE_ID,
        formRef.current,
        process.env.GATSBY_EMAILJS_PUBLIC_KEY
      )
      .then(
        (results) => {
          setFormSubmitStatus("success");
        },
        (error) => {
          setFormSubmitStatus("fail");
        }
      );
  };

  return (
    <Layout>
      <PageDetails heading={pageTitle} description={pageDescription} />
      <section className={`grid-wrapper ${contactForm}`}>
        {formSubmitStatus === "pending" && (
          <form ref={formRef} onSubmit={handleSubmit}>
            <label htmlFor="firstName">
              <span>First Name</span>
              <input id="firstName" type="text" name="firstName" />
            </label>
            <label htmlFor="lastName">
              <span>Last Name</span>
              <input id="lastName" type="text" name="lastName" />
            </label>
            <label htmlFor="email">
              <span>Email</span>
              <input id="email" type="email" name="email" />
            </label>
            <label htmlFor="pronouns">
              <span>Pronouns</span>
              <input id="pronouns" type="text" name="pronouns" />
            </label>
            <label htmlFor="message">
              <span>Message</span>
              <textarea name="message" id="message"></textarea>
            </label>
            <button type="submit">Send Message</button>
          </form>
        )}
        {formSubmitStatus === "success" && (
          <div className={submitMessage}>
            <h2>Success!</h2>
            <p>
              Your message is on it's way to my inbox and I will get back to you
              as soon as I can. While you're waiting, why not checkout some of
              my <Link to="/projects">upcoming projects.</Link>
            </p>
          </div>
        )}
        {formSubmitStatus === "fail" && (
          <div className={submitMessage}>
            <h2>Hmm something's not right...</h2>
            <p>
              Unfortunately your message was not sent. Click the button below to
              try again. If you're still having issues, you contact me directly
              on instagram.
            </p>
          </div>
        )}
      </section>
    </Layout>
  );
};

export default Contact;

export const contactQuery = graphql`
  query {
    contentfulContactPage {
      pageTitle
      pageDescription {
        raw
      }
    }
  }
`;

export { Head } from "../components/Head/Head";
